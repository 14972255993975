import React from "react";
import { motion } from "framer-motion";
import {useTheme} from "@mui/material";

const BlobAnimation = () => {
    const theme = useTheme()
    const startPath = "M51.1,-70.8C66.2,-59.3,78.6,-44.4,85,-27.1C91.3,-9.7,91.7,10.1,85.7,27.6C79.7,45.2,67.4,60.5,52,70.5C36.7,80.6,18.3,85.5,0.2,85.2C-17.9,85,-35.8,79.5,-50.2,69C-64.5,58.6,-75.3,43.3,-81.9,25.9C-88.5,8.5,-91,-10.9,-86.2,-28.7C-81.3,-46.4,-69.2,-62.5,-53.6,-73.9C-38.1,-85.3,-19,-92,-0.5,-91.3C18,-90.6,35.9,-82.4,51.1,-70.8Z";
    const endPath = "M39.4,-56.1C50.3,-46.2,57.9,-33.8,65.8,-19.1C73.7,-4.4,81.9,12.4,80.6,29.3C79.4,46.1,68.7,63,53.7,72.9C38.7,82.9,19.3,85.8,2.6,82.2C-14.1,78.6,-28.2,68.4,-43.8,58.7C-59.5,49,-76.7,39.7,-85.2,25C-93.8,10.2,-93.8,-10.1,-87.2,-27.7C-80.6,-45.2,-67.4,-59.9,-51.8,-68.3C-36.2,-76.6,-18.1,-78.5,-1.9,-75.9C14.2,-73.2,28.4,-66,39.4,-56.1Z";

    return (
        <div className="blob-container">
            <motion.div
                style={{width:'100%', aspectRatio:'1/1'}}
                animate={{ rotate: 360 }}
                transition={{ duration: 15, repeat: Infinity }}
            >
                <motion.svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 200"
                >
                    <motion.path
                        d={startPath}
                        transform={"translate(100,100)"}
                        opacity={0.5}
                        transition={{ duration: 3, repeat: Infinity, repeatType: "reverse"}}
                        animate={{ d: endPath }}
                        fill={theme.palette.primary.main}
                    />
                </motion.svg>
            </motion.div>
        </div>
    );
};

export default BlobAnimation;
