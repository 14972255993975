import {firestore} from "../firebase/clientApp";

export default function findAnswer(id, elementPlaying, user) {
    return new Promise((resolve, reject) => {
        var docRef = firestore.collection("users/plesh/events/" + id + "/queue/" + elementPlaying.id + "/answers").doc(user.uid);

        docRef.get().then((doc) => {
            if (doc.exists) {
                resolve(doc.data().answer)
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
            reject(error);
        });
    });
}