import React from 'react'
import {Button, Grid, Typography} from "@mui/material";

export default function SiNoQuestion({tipo, sendAnswer, answer}) {
    return (
        <Grid sx={{position: 'relative'}} container justifyContent={"center"} alignItems={"center"}
              style={{textAlign: 'center', height: '100%', position:'relative'}} spacing={5}>
            <Grid item xs={12} height={'50%'}>
                <Button disabled={answer!==''} onClick={() => sendAnswer('yes')} variant={'siNo'} risposta={'si'} style={{opacity: answer==='no' && '0.2'}}>
                    <Typography variant={'h3'} fontWeight={'bold'} color={tipo === 'clickTo' ? '#263238' : ''}>Si</Typography>
                </Button>
            </Grid>
            <Grid item xs={12} height={'50%'}>
                <Button disabled={answer!==''} onClick={() => sendAnswer('no')} variant={'siNo'} risposta={'no'} style={{opacity: answer==='yes' && '0.2'}}>
                    <Typography variant={'h3'} fontWeight={'bold'}>No</Typography>
                </Button>
            </Grid>
        </Grid>
    )
}
