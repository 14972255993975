import React, {useEffect, useState} from "react";
import {Backdrop, Box, CircularProgress} from "@mui/material";
import {ClickToQuestion} from "./types/ClickToQuestion";
import SiNoQuestion from "./types/SiNoQuestion";
import {SendPhotoQuestion} from "./types/SendPhotoQuestion";
import SentimentQuestion from "./types/SentimentQuestion";
import QuizQuestion from "./types/QuizQuestion";
import WordcloudQuestion from "./types/WordcloudQuestion";
import ToolpressQuestion from "./types/ToolpressQuestion";
import {useParams} from "react-router-dom";
import {auth, firestore} from "../../firebase/clientApp";
import axios from "axios";
import {API_URL} from "../../config";
import {AltroQuestion} from "./types/AltroQuestion";
import {AudioQuestion} from "./types/AudioQuestion";
import findAnswered from "../../functions/findAnswered";
import findAnswer from "../../functions/findAnswer";

export const QuestionSwitch = ({elementPlaying, theme}) => {
    const {id} = useParams()

    const [answer,setAnswer] = useState('')
    const [answered, setAnswered] = useState(false)
    const [sending, setSending] =useState(false)
    const [success, setSuccess] =useState(false)
    const user = auth.currentUser

    useEffect(()=> {
        findAnswered(id, elementPlaying, user)
            .then(answered => {
                setAnswered(answered)
            })
        findAnswer(id, elementPlaying, user)
            .then(answer => {
                setAnswer(answer)
            })
    },[elementPlaying])

    function sendAnswer(answer) {
        let data = {
            answer: answer,
            user:user.uid
        }
        setSuccess(false)
        setSending(true)
        axios.post(`${API_URL}/answer/${id}/${elementPlaying.id}`, data)
            .then(response => {
                setSuccess(true)
                setAnswered(true)
                setSending(false)
                setAnswer(answer)
            })
            .catch( error => {
                setSending(false)
            })
    }

    const commonProps = {
        sendAnswer: sendAnswer,
        answer: answer,
        sending: sending
    };

    const componentProps = {
        'clickTo': { element: elementPlaying },
        'siNo': { tipo: elementPlaying.tipo, ...commonProps },
        'sendPhoto': { elementId: elementPlaying.id, ...commonProps },
        'sentiment': { tipo: elementPlaying.tipo, theme: theme, ...commonProps },
        'quiz': { risposte: elementPlaying.risposte, corretta: elementPlaying.corretta, ...commonProps },
        'poll': { risposte: elementPlaying.risposte, isPoll: true, ...commonProps },
        'wordcloud': { elementId: elementPlaying.id, tipo: elementPlaying.tipo, answered: answered, success: success, ...commonProps },
        'toolpress': { elementPlaying: elementPlaying },
        'altro': { elementPlaying: elementPlaying },
        'audio': { elementId: elementPlaying.id, ...commonProps }
    };

    const componentMap = {
        'clickTo': ClickToQuestion,
        'siNo': SiNoQuestion,
        'sendPhoto': SendPhotoQuestion,
        'sentiment': SentimentQuestion,
        'quiz': QuizQuestion,
        'poll': QuizQuestion,
        'wordcloud': WordcloudQuestion,
        'toolpress': ToolpressQuestion,
        'altro': AltroQuestion,
        'audio': AudioQuestion
    };

    return(
        <Box>
            <Backdrop
                sx={{ color: '#f1ebe9', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sending}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {
                componentMap[elementPlaying.tipo]
                        ? React.createElement(componentMap[elementPlaying.tipo], componentProps[elementPlaying.tipo])
                        : null

            }
        </Box>
    )
}