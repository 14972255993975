import {BadWords} from '../assets/data/bad_words.js'

export default function wordCheck(arg) {

  const emoji = /[\uD800-\uDBFF]|[\u2702-\u27B0]|[\uF680-\uF6C0]|[\u24C2-\uF251]/g

  const num = /^\d+$/g

  const punti = /[!"#$%&'()*+,\-.\/\:;<=>?@\[\\\]^_‘{|}~£]/g
  let parola = arg.toLowerCase()
  if (
    parola.includes(' ') ||
    parola.length < 2 ||
    emoji.test(parola) ||
    punti.test(parola) ||
    BadWords.includes(parola) ||
    num.test(parola)
  ) {
    return false
  } else {
    return true
  }
}
