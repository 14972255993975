import React, {useEffect, useRef, useState} from "react";
import {Box, Button, CircularProgress, IconButton, Typography, useTheme} from "@mui/material";
import {firebase} from "../../../firebase/clientApp";
import {useParams} from "react-router-dom";
import RecordRTC, {invokeSaveAsDialog} from 'recordrtc';
import {Audiotrack, Mic, Send, VolumeUp} from "@mui/icons-material";
import BlobAnimation from "./AudioQuestion/BlobAnimation";
import axios from "axios";
import {API_URL} from "../../../config";
import {CustomAlert} from "../../shared/CustomAlert";

export const AudioQuestion = ({elementId}) => {
    const theme = useTheme()
    const {id} = useParams()
    const [stream, setStream] = useState(null);
    const [blob, setBlob] = useState(null);
    const refAudio = useRef(null);
    const recorderRef = useRef(null);
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const [recording, setRecording] = useState(false)

    const handleRecording = async () => {
        setRecording(true)
        // const cameraStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        const mediaStream = await navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
        });

        let config = {
            type: 'audio',
            mimeType: 'audio/mp4',
            audioBitsPerSecond: 256000,
        }

        setStream(mediaStream);
        recorderRef.current = new RecordRTC(mediaStream, config);
        recorderRef.current.startRecording();
    };

    const handleStop = () => {
        setRecording(false)
        recorderRef.current.stopRecording(() => {
            setBlob(recorderRef.current.getBlob());
        });
    };

    useEffect(() => {
        if (!refAudio.current) {
            return;
        }

        // refVideo.current.srcObject = stream;
    }, [stream, refAudio]);


    const sendAudio = async () => {
        setSending(true)
        const formData = new FormData();
        let file = new File([blob], 'audio.mp4', {type: 'audio/mp4'})
        formData.append('file', file);
        formData.append('user', firebase.auth().currentUser.uid);
        await axios.post(`${API_URL}/answer/${id}/${elementId}/audio`, formData)
            .then(() => {
                setSuccess(true)
                setBlob(null)
            })
            .catch(() =>{})
        setSending(false)
    }

    return (
        <Box sx={{height:'100%'}}>
            <CustomAlert open={success} setOpen={setSuccess} severity={'success'} message={'Audio inviato con successo'}/>
            <Typography textAlign={'center'}>
                {blob ? "Tieni premuto per registrare di nuovo" : "Tieni premuto per registrare"}
            </Typography>
            <Box sx={{position:'relative', height:'100%', py:8}}>
                <Box sx={{zIndex:"2", position:'relative', textAlign:'center'}}>
                    <IconButton sx={{
                        background: recording ? theme.palette.primary.main : theme.palette.secondary.main,
                        fontSize: {xs:100, md:130},
                        p: 4,
                        "&:hover": {
                            background: recording ? theme.palette.primary.main : theme.palette.secondary.main,
                        },
                        "&:active": {
                            background: recording ? theme.palette.primary.main : theme.palette.secondary.main,
                        },
                    }} onTouchStart={handleRecording} onTouchEnd={handleStop} onTouchCancel={handleStop}>
                        <Mic fontSize={'inherit'}/>
                    </IconButton>
                </Box>
                <Box sx={{
                    position: 'absolute',
                    top: "50%",
                    left: '50%',
                    overflow: 'hidden',
                    transform: 'translate(-50%,-50%)',
                    width: recording ? 'min(16rem, 80%)' : 'min(16rem, 70%)',
                    aspectRatio: '1/1',
                    zIndex: "1"
                }}>
                    {recording ? <BlobAnimation/> :
                        <Box sx={{background: theme.palette.secondary.main, opacity:'0.5', width:'100%', height:'100%', borderRadius:'50%'}}>
                        </Box> }
                </Box>
            </Box>
            {blob && <Box sx={{pb:2, textAlign:'center'}}>
                <Button endIcon={<VolumeUp/>} onClick={() => refAudio.current?.play()}>
                    riproduci
                </Button>
                <audio ref={refAudio} src={URL.createObjectURL(blob)}/>
            </Box>}
            <Box sx={{textAlign:'center', pt:2}}>
                {sending ? <CircularProgress/> : <Button endIcon={<Send/>} sx={{opacity: blob ? 1 : 0.2}} variant={'default'} disabled={!blob} onClick={sendAudio}>
                    Invia
                </Button>}
            </Box>
        </Box>
    );
}